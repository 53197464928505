import {
  DashboardOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  PercentageOutlined,
  ShoppingOutlined,
  BarChartOutlined,
  PieChartOutlined,
  FilterOutlined,
  LogoutOutlined,
  StarOutlined,
  UserOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

export const MenusDynamic = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: <Link to="/admin/">Dashboard</Link>,
    path: "/admin/",
  },
  // {
  //   key: "2",
  //   icon: <SettingOutlined />,
  //   label: "Company",
  //   path: "/admin/company_settings",
  //   children: [
  //     {
  //       key: "2a",
  //       icon: null,
  //       label: <Link to="/admin/company_settings/country">Country</Link>,
  //       path: "/admin/company_settings/country",
  //     },
  //     {
  //       key: "2b",
  //       icon: null,
  //       label: <Link to="/admin/company_settings/state">State</Link>,
  //       path: "/admin/company_settings/state",
  //     },
  //     {
  //       key: "2c",
  //       icon: null,
  //       label: <Link to="/admin/company_settings/city">City</Link>,
  //       path: "/admin/company_settings/city",
  //     },
  //     {
  //       key: "2d",
  //       icon: null,
  //       label: (
  //         <Link to="/admin/company_settings/company_profile">Company Profile</Link>
  //       ),
  //       path: "/admin/company_settings/company_profile",
  //     },
  //     {
  //       key: "2e",
  //       icon: null,
  //       label: (
  //         <Link to="/admin/company_settings/pincode_availability">
  //           Pincode Availability
  //         </Link>
  //       ),
  //       path: "/admin/company_settings/pincode_availability",
  //     },
  //   ],
  // },
  // {
  //   key: "bb",
  //   icon: <FileTextOutlined />,
  //   label: "Blogs",
  //   path: "/admin/products",
  //   children: [
  //     {
  //       key: "bbb",
  //       icon: null,
  //       label: <Link to="/admin/blog/category">Category</Link>,
  //       path: "/admin/blog/category",
  //     },
  //     {
  //       key: "bbc",
  //       icon: null,
  //       label: <Link to="/admin/blog/blog">All Blogs</Link>,
  //       path: "/admin/blog/blog",
  //     },
  //     // {
  //     //   key: "3d",
  //     //   icon: null,
  //     //   label: <Link to="/admin/products/attributes">Attributes</Link>,
  //     //   path: "/admin/products/attributes",
  //     // },
  //   ],
  // },
  {
    key: "3",
    icon: <ShoppingCartOutlined />,
    label: "Products",
    path: "/admin/products",
    children: [
      {
        key: "3a",
        icon: null,
        label: <Link to="/admin/products/brands">Brands</Link>,
        path: "/admin/products/brands",
      },
      {
        key: "3b",
        icon: null,
        label: <Link to="/admin/products/category">Category</Link>,
        path: "/admin/products/category",
      },
      {
        key: "3c",
        icon: null,
        label: <Link to="/admin/products/products">Products</Link>,
        path: "/admin/products/products",
      },
      // {
      //   key: "3d",
      //   icon: null,
      //   label: <Link to="/admin/products/attributes">Attributes</Link>,
      //   path: "/admin/products/attributes",
      // },
      {
        key: "3e",
        icon: null,
        label: <Link to="/admin/products/couriers">Courier Partners</Link>,
        path: "/admin/products/couriers",
      },
    ],
  },
  {
    key: "9111",
    icon: <UserOutlined />,
    label: <Link to="/admin/customers">Customers</Link>,
    path: "/admin/customers",
  },
  {
    key: "911",
    icon: <StarOutlined />,
    label: <Link to="/admin/ratings">Ratings</Link>,
    path: "/admin/ratings",
  },
  // {
  //   key: "4",
  //   icon: <PieChartOutlined />,
  //   label: "Masters",
  //   path: "/admin/masters",
  //   children: [
  //     {
  //       key: "4a",
  //       icon: null,
  //       label: <Link to="/admin/masters/tax">Tax</Link>,
  //       path: "/admin/masters/tax",
  //     },
  //     {
  //       key: "4b",
  //       icon: null,
  //       label: <Link to="/admin/masters/delivery_charges">Delivery Charges</Link>,
  //       path: "/admin/masters/delivery_charges",
  //     },
  //     {
  //       key: "4c",
  //       icon: null,
  //       label: <Link to="/admin/masters/customers_type">Customers Type</Link>,
  //       path: "/admin/masters/customers_type",
  //     },
  //     {
  //       key: "4d",
  //       icon: null,
  //       label: <Link to="/admin/masters/roles_permission">Roles Permission</Link>,
  //       path: "/admin/masters/roles_permission",
  //     },
  //   ],
  // },
  // {
  //   key: "5",
  //   icon: <PercentageOutlined />,
  //   label: "Offers",
  //   path: "/admin/offers",
  //   children: [
  //     {
  //       key: "5a",
  //       icon: null,
  //       label: <Link to="/admin/offers/offers">Offers</Link>,
  //       path: "/admin/offers/offers",
  //     },
  //     {
  //       key: "5b",
  //       icon: null,
  //       label: <Link to="/admin/offers/discounts">Discounts</Link>,
  //       path: "/admin/offers/discounts",
  //     },
  //     {
  //       key: "5c",
  //       icon: null,
  //       label: <Link to="/admin/offers/coupons">Coupons</Link>,
  //       path: "/admin/offers/coupons",
  //     },
  //   ],
  // },
  // {
  //   key: "6",
  //   icon: <BarChartOutlined />,
  //   label: "Inventory",
  //   path: "/admin/inventory",
  //   children: [
  //     {
  //       key: "6a",
  //       icon: null,
  //       label: <Link to="/admin/inventory/stocks">Stocks</Link>,
  //       path: "/admin/inventory/stocks",
  //     },
  //     {
  //       key: "6b",
  //       icon: null,
  //       label: <Link to="/admin/inventory/stocks_journal">Stocks Journal</Link>,
  //       path: "/admin/inventory/stocks_journal",
  //     },
  //   ],
  // },
  {
    key: "7",
    icon: <ShoppingOutlined />,
    label: "Orders",
    path: "/admin/orders",
    children: [
      {
        key: "7a",
        icon: null,
        label: <Link to="/admin/orders/sales">Orders</Link>,
        path: "/admin/orders/sales",
      },
      {
        key: "7c",
        icon: null,
        label: <Link to={void 0}>Payment History</Link>,
        path: "/admin/orders/payment_history",
      },
    ],
  },
  {
    key: "8",
    icon: <BarChartOutlined />,
    label: "Analytics",
    path: "/admin/analytics",
    children: [
      {
        key: "8a",
        icon: null,
        label: <Link to={"/admin/analytics/overview"}>Overview</Link>,
        path: "/admin/analytics/overview",
      },
      {
        key: "8b",
        icon: null,
        label: <Link to={"/admin/analytics/products"}>Products</Link>,
        path: "/admin/analytics/products",
      },
      {
        key: "8d",
        icon: null,
        label: <Link to={"/admin/analytics/orders"}>Orders</Link>,
        path: "/admin/analytics/orders",
      },
      {
        key: "8f",
        icon: null,
        label: <Link to={"/admin/analytics/customers"}>Customers</Link>,
        path: "/admin/analytics/customers",
      },
      {
        key: "8g",
        icon: null,
        label: <Link to={"/admin/analytics/monthly_report"}>Reports</Link>,
        path: "/admin/analytics/monthly_report",
      },
    ],
  },
  {
    key: "9",
    icon: <LogoutOutlined />,
    label: "Logout",
    path: "/admin/logout",
  },
];
