import React, { useState } from "react";
import Context from "./index";
import { create, getAll } from "../../Api/WebApi";
import { message } from "antd";

const CartProvider = ({ children, ...props }) => {
  const [cartListLoader, setCartListLoader] = useState(false);
  const [cartList, setCartList] = useState([]);
  const [cartDeleteLoader, setCartDeleteLoader] = useState(false);
  const [cartAddLoader, setCartAddLoader] = useState(false);
  const [cartUpdateLoader, setCartUpdateLoader] = useState(false);
  const [currentCart, setCurrentCart] = useState(null);
  const [couponText, setCouponText] = useState("");
  const [couponApplyLoader, setCouponApplyLoader] = useState(false);
  const [couponRemoveLoader, setCouponRemoveLoader] = useState(false);
  const [viewCouponPopup, setViewCouponPopup] = useState(false);
  async function addCoupon(code) {
    setCouponApplyLoader(true);
    await create("coupon.php?action=applycoupon", {
      coupon_code: code,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          message.success(res?.message);
          getCartList();
          setCouponApplyLoader(false);
        } else {
          message.error(res?.message);
          setCouponApplyLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setCouponApplyLoader(false);
      });
  }

  async function removeCoupon() {
    setCouponRemoveLoader(true);
    await getAll("coupon.php?action=deletecoupon")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          message.success(res?.message);
          getCartList();
          setCouponRemoveLoader(false);
        } else {
          message.error(res?.message);
          setCouponRemoveLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setCouponRemoveLoader(false);
      });
  }

  async function getCartList() {
    setCartListLoader(true);
    const cart_list = localStorage.getItem("nattu_cart");
    const data = cart_list ? JSON.parse(cart_list).cart_items : [];
    await create("cart.php?action=cartstorage", { cart_items: data })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setCartList(res?.data);
          setCouponText(res?.data?.coupon?.applied_coupon || "");
          const update_storage = res?.data?.items?.map((item) => {
            return {
              product_id: item?.product_id,
              quantity: item?.quantity,
            };
          });
          localStorage.setItem(
            "nattu_cart",
            JSON.stringify({ cart_items: update_storage })
          );
          setCartListLoader(false);
          if (localStorage.getItem("nattu_customer_login") === "true") {
            authCartUpdate();
          }
        } else {
          setCartList([]);
          setCartListLoader(false);
        }
      })
      .catch((err) => {
        setCartList([]);
        setCartListLoader(false);
      });
  }
  async function authCartUpdate() {
    const token = localStorage.getItem("nattu_customer_login");
    if (token !== "true") {
      return;
    }
    const cart_list = localStorage.getItem("nattu_cart");
    const data = cart_list ? JSON.parse(cart_list).cart_items : [];
    await create("cart.php?action=authcartstorage", { cart_items: data })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
        } else {
        }
      })
      .catch((err) => {});
  }

const getTotalCartQuantity = (cart) => {
  return cart.cart_items.reduce((total, item) => total + item.quantity, 0);
};

  async function deleteCart(id) {
    setCartDeleteLoader(true);
    try {
      let cart_list = localStorage.getItem("nattu_cart");
      let cart = cart_list ? JSON.parse(cart_list) : { cart_items: [] };
      const updatedCart = cart.cart_items.filter(
        (item) => item.product_id !== id
      );
      localStorage.setItem(
        "nattu_cart",
        JSON.stringify({ cart_items: updatedCart })
      );

      setCartList(updatedCart);
      const totalQuantity = getTotalCartQuantity({ cart_items: updatedCart });
      const storedSession = localStorage.getItem("customer_session");
      let parsedSession = JSON.parse(storedSession);
      if (parsedSession?.value) {
        addSessionOverview(parsedSession.value, totalQuantity);
      }
      setCartDeleteLoader(false);
    } catch (error) {
      setCartDeleteLoader(false);
    }
    getCartList();
  }

  async function addCart(product_id, quantity = 1) {
    setCartAddLoader(true);
    try {
      let cart_list = localStorage.getItem("nattu_cart");
      let cart = cart_list ? JSON.parse(cart_list) : { cart_items: [] };
      const find_product = cart.cart_items.find(
        (item) => item.product_id === product_id
      );
      if (find_product) {
        find_product.quantity += quantity;
      } else {
        cart.cart_items.push({ product_id, quantity: 1 });
      }
      localStorage.setItem("nattu_cart", JSON.stringify(cart));
      setCartList(cart.cart_items);
      const totalQuantity = getTotalCartQuantity(cart);
      const storedSession = localStorage.getItem("customer_session");
      let parsedSession = JSON.parse(storedSession);
      if (parsedSession?.value) {
        addSessionOverview(parsedSession.value, totalQuantity);
      }
      setCartAddLoader(false);
    } catch (error) {
      setCartAddLoader(false);
    }
    getCartList();
  }

  async function updateCart(product_id, cart_type = "plus") {
    setCartUpdateLoader(true);
    try {
      let cart_list = localStorage.getItem("nattu_cart");
      let cart = cart_list ? JSON.parse(cart_list) : { cart_items: [] };
      const find_product = cart.cart_items.find(
        (item) => item.product_id === product_id
      );
      if (find_product) {
        if (cart_type === "plus") {
          find_product.quantity += 1;
        } else if (cart_type === "minus") {
          find_product.quantity -= 1;
          if (find_product.quantity <= 0) {
            cart.cart_items = cart.cart_items.filter(
              (item) => item.product_id !== product_id
            );
          }
        }
        localStorage.setItem("nattu_cart", JSON.stringify(cart));
        setCartList(cart.cart_items);
        const totalQuantity = getTotalCartQuantity(cart);
        const storedSession = localStorage.getItem("customer_session");
        let parsedSession = JSON.parse(storedSession);
        if (parsedSession?.value) {
          addSessionOverview(parsedSession.value, totalQuantity);
        }        
        setCartUpdateLoader(false);
      } else {
        setCartUpdateLoader(false);
      }
    } catch (error) {
      setCartUpdateLoader(false);
    }
    getCartList();
  }

const addSessionOverview = async (sessionId, totalQuantity) => {
    try {
      const res = await create("cart.php?action=updatecartsession", {
        session_id: sessionId,
        quantity: totalQuantity,  // Pass total quantity here
      });

      if (res?.status === 200 && res?.success === true) {
        console.log("Session updated successfully.");
      } else {
        console.error("Failed to update session.");
      }
    } catch (err) {
      console.error("Error in updating session:", err);
    }
};

  return (
    <Context.Provider
      value={{
        ...props,
        cartListLoader,
        setCartListLoader,
        cartList,
        setCartList,
        getCartList,
        deleteCart,
        cartDeleteLoader,
        setCartDeleteLoader,
        addCart,
        cartAddLoader,
        setCartAddLoader,
        updateCart,
        currentCart,
        setCurrentCart,
        cartUpdateLoader,
        setCartUpdateLoader,
        couponApplyLoader,
        setCouponApplyLoader,
        couponRemoveLoader,
        setCouponRemoveLoader,
        addCoupon,
        removeCoupon,
        couponText,
        setCouponText,
        viewCouponPopup,
        setViewCouponPopup,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default CartProvider;
